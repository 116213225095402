
footer {
    font-weight: 500;
    background-color: rgb(255, 255, 255);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px 20px;
}

.div1{
    padding: 5px 35px;
}

.div2 {
    margin: auto;
}

.footer-menu{
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center; 
    list-style: none;
    z-index: 10000;
    transition: 0.6s;
}


@media (max-width:786px) {
    .footer-menu {
        display: none;
    }
}