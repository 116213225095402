.pre{
    margin: 0;
    padding: 0;
    background: #563535;
    font-family: sans-serif;

}
.pre .container{
    width: auto;
    margin: 70px auto 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 120px;
}
.pre .container .box{
    position: relative;
    width: 300px;
    height: 300px ;
    background: yellow;
    margin: 10px;
    box-sizing: border-box;
    display: inline-block;
}
.pre .container .box .imgBox
{
    position: relative;
    overflow: hidden;
}
.pre .container .box .imgBox img
{
    max-width: 100%;
    transition: transform 2s;
}
.pre .container .box:hover .imgBox img
{
    transform: scale(1.2);
}
.pre .container .box .details
{
    position: absolute;
    top: 10px;
    left: 10px;
    bottom: 10px;
    right: 10px;
    background: rgba(0, 0, 2, .4);
    transform: scaleY(0) ;
    transition: transform .5s;
}

.pre .container .box:hover .details
{
    transform: scaleY(1) ;
}

.pre .container .box .details .content
{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    padding:15px ;
    color: black;
}

.pre .container .box .details .content h2
{
    margin: 0;
    padding: 0;
    font-size: 20px;
    color: yellow;
}

.pre .container .box .details .content p
{
    margin: 10px 0 0;
    padding: 0;
    color: white;

}

.pre h1{
    color: white;
    text-align: center;
    font-size: 40px;
}