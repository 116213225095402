@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: white;
  min-height: auto;
}

.container {
  max-width: 80%;
  margin: auto;
}

/* .flex {
  display: flex;
  justify-content: space-between;
} */

ul {
  list-style: none;
}

a {
  text-decoration: none;
}


/* -----------------Header--------------*/

/* header {
  padding: 20px 0; 
}

header ul {
  display: inline-block;
}

header ul li {
  display: inline-block;
  margin-left: 30px;
}

header ul li a {
  font-weight: 500;
  transition: 0.4s;
}

header ul li a:hover {
  color: rgb(186, 141, 49);
  font-weight: 500;
} */

/* -----------------Header--------------*/

/* -----------------Home--------------*/
/* .left, 
.right {
  width: 50%;
  position: relative;
}

.flex {
  display: flex;
}

.topMargin {
  margin-top: 80px;
}

.home {
  height: 90vh;
  width: 100%;
}

.home .img {
  height: 90vh;
  width: 100%;
  position: relative;
}

.home img {
  position: absolute;
  top: 0;
  left: 0;
  height: 90vh;
  width: 100%;
  object-fit: contain;
  transform: scale(1.1);
} */


/* -----------------Home--------------*/